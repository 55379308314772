export const SHOW_LOADING = 'SHOW_LOADING';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_IS_EMPLOYER = 'UPDATE_IS_EMPLOYER';
export const UPDATE_HEADER_DATA = 'UPDATE_HEADER_DATA';
export const UPDATE_CANDIDATE_LIST = 'UPDATE_CANDIDATE_LIST';
export const UPDATE_CANDIDATE_FILTERS = 'UPDATE_CANDIDATE_FILTERS';
export const UPDATE_JOB_LIST = 'UPDATE_JOB_LIST';
export const UPDATE_JOB_FILTERS = 'UPDATE_JOB_FILTERS';
export const UPDATE_SELECTED_CANDIDATE = 'UPDATE_SELECTED_CANDIDATE';
export const UPDATE_SELECTED_JOB_ID = 'UPDATE_SELECTED_JOB_ID';
export const CANDIDATE_PROFILE = 'CANDIDATE_PROFILE';
export const CURRECY_OPTION = 'CURRECY_OPTION';
export const JOB_TYPE_OPTION = 'JOB_TYPE_OPTION';
export const MASTER_DATA_OPTION = 'MASTER_DATA_OPTION';
