export const API_ENDPOINT = {
  getFullLocation: 'v2/getfulllocation',
  getSkills: 'v2/getskill',
  getJobTitle: 'v2/getjobtitle',
  employmentType: 'v2/employmenttypedropdown',
  masterData: 'v2/masterdropdown',
  workVisa: 'v2/workvisadropdown',
  roleData: 'v2/roledropdown',
  getCompany: 'v2/getcompany',
  tags: 'v2/emp/tags',
  getCountry: 'v2/countrydropdown',
  getState: 'v2/statedropdown',
  getCity: 'v2/citydropdown',
  getDomain: 'v2/domaindropdown',
  getCurrencyOpt: 'v2/getcurrencies',
  changePassword: 'v2/emp/changepassword',
  getEducation: 'v2/geteducation',
  login: 'v2/emp/login',
  dashboard: 'v2/emp/dashboard',
  headerData: 'v2/emp/headerdata',
  orderHistory: 'v2/emp/myorder',
  allocationHistory: 'v2/emp/allocations',
  register: 'v2/emp/register',
  register2: 'v2/emp/register2',
  register_otp_verify: 'v2/emp/signupotpverify',
  register_otp_resend: 'v2/emp/resendsignupotp',
  forgot_password_otp_resquest: 'v2/emp/forgetpassword',
  forgot_password_otp_resend: 'v2/emp/resendforgetotp',
  forgot_password_otp_verify: 'v2/emp/forgetotpverify',
  password_reset: 'v2/emp/setnewpassword',
  jobList: 'v2/emp/joblist',
  viewJob: 'v2/emp/viewjob',
  postJob: 'v2/emp/postjob',
  premiumRequest: 'v2/emp/premiumsearch',
  addPremiumRequest: 'v2/emp/premiumsearch',
  searchCandidate: 'v2/emp/search',
  taggedCandidate: 'v2/emp/tagedcandidate',
  recuriterProfile: 'v2/emp/fetchrecruiter',
  updateRecuriterProfile: 'v2/emp/updaterecruiter',
  subUser: 'v2/emp/subuser',
  deletesubUser: 'v2/emp/deleteuser',
  deletesubUsers: 'v2/emp/deletesubusers',
  allocate: 'v2/emp/allocate',
  uploadProfilePic: 'v2/emp/updaterecruiterpic',
  deleteProfilePic: 'v2/emp/deleterecruiterpic',
  deleteRecruiterVideo: 'v2/emp/deleterecruitervideo',
  deleteCompanyLogo: 'v2/emp/deletecompanylogo',
  deleteCompanyVideo: 'v2/emp/deletecompanyvideo',
  premiumPackData: 'v2/emp/premiumpackdropdown',
  savedCandidate: 'v2/emp/save',
  downloadCandidate: 'v2/emp/download',
  shortListed: 'v2/emp/new-application',
  application: 'v2/emp/application',
  postJobUserList: 'v2/emp/postjobuserlist',
  fetchJob: 'v2/emp/fetchjob',
  interview: 'v2/emp/interview',
  positionList: 'v2/emp/interview/positionlist',
  interviewStatusList: 'v2/emp/interview/statuslist',
  fetchCompany: 'v2/emp/fetchcompany',
  updatecompany: 'v2/emp/updatecompany',
  updatecompanylogo: 'v2/emp/updatecompanylogo',
  interviewDetails: 'v2/emp/interview/view',
  getCandidateInfoInterview: 'v2/emp/interview/getcandidatecontact',
  candidateDetails: 'v2/emp/candidate',
  fetchChatList: 'v2/emp/fetchChatList',
  fetchChat: 'v2/emp/fetchChat',
  sendChatMessage: 'v2/emp/msg2cand',
  updateJobStatus: 'v2/emp/modifyjobstatus',
  subscription: 'v2/emp/packages',
  moveToActive: 'v2/emp/flip-job',
  updateRecruiterVideo: 'v2/emp/updaterecruitervideo',
  updateCompanyVideo: 'v2/emp/updatecompanyvideo',
  candidateReview: 'v2/emp/review',
  zipByCity: 'v2/zipbycity',
  contactUs: 'v2/emp/contactus',
  emailvalidate: 'v2/emp/emailvalidate',
  markAsReadMSG: 'v2/emp/updatecountmessageemployer',
  searchLocationWithStateCountry: 'v2/emp/getlocations',
  searchHistory: 'v2/emp/getsearchhistory',
  deleteSearchHistory: 'v2/emp/deletesearchhistory',
  saveSearchHistory: 'v2/emp/savesearchhistory',
  downloadResumeUpdate: 'v2/emp/downloadresumeupdate',
  draftJob: 'v2/emp/draftjob',
  getDraftJob: 'v2/emp/getdraftjob',
  deleteDraftJob: 'v2/emp/deletedraftjob',
  getPreSignedUrl: 'v2/emp/getpresignurl'
};
