import {
  SHOW_LOADING,
  HIDE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_SELECTED_CANDIDATE,
  UPDATE_SELECTED_JOB_ID,
  CURRECY_OPTION,
  JOB_TYPE_OPTION,
  MASTER_DATA_OPTION
} from '../mutations';

const initialState = {
  spinner: 0,
  notification: null,
  showNotification: 0,
  selectedCandidate: null,
  selectedJobId: null,
  currencyOpt: [],
  jobTypeOpt: [],
  masterDataOpt: {
    workVisaList: [],
    'EDUCATION LEVEL': []
  }
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        spinner: action.payload ? state.spinner + 1 : state.spinner - 1
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: 0
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
        showNotification: state.showNotification + 1
      };
    case UPDATE_SELECTED_CANDIDATE:
      return {
        ...state,
        selectedCandidate: action.payload
      };
    case UPDATE_SELECTED_JOB_ID:
      return {
        ...state,
        selectedJobId: action.payload
      };
    case CURRECY_OPTION:
      return {
        ...state,
        currencyOpt: action.payload
      };
    case JOB_TYPE_OPTION:
      return {
        ...state,
        jobTypeOpt: action.payload
      };
    case MASTER_DATA_OPTION:
      return {
        ...state,
        masterDataOpt: { ...state.masterDataOpt, [action.key]: action.payload }
      };
    default:
      return state;
  }
}
