import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
  const appRoutes = [
    {
      path: '/',
      children: [
        {
          index: true,
          element: lazyLoadRoutes('home')
        },
        {
          path: routes.login,
          element: lazyLoadRoutes('auth/login')
        },
        {
          path: routes.registerOtpVerify,
          element: lazyLoadRoutes('auth/register-otp-verify')
        },
        {
          path: routes.resetPassword,
          element: lazyLoadRoutes('auth/reset-password')
        },
        {
          path: routes.forgotPassword,
          element: lazyLoadRoutes('auth/forgot-password')
        },
        {
          path: routes.dashboard,
          element: lazyLoadRoutes('Dashboard', true)
        },

        /**
         * Candidate routes
         */
        {
          path: routes.candidateSearchHistory,
          element: lazyLoadRoutes('candidate/CandidateSearchHistory', true)
        },
        {
          path: routes.docxViewer,
          element: lazyLoadRoutes('DocxViewer', true, false)
        },
        {
          path: routes.taggedCandidate,
          element: lazyLoadRoutes('candidate/tagged', true)
        },
        {
          path: `${routes.candidateProfile}/:id/:job_id`,
          element: lazyLoadRoutes('candidate/profile', true)
        },
        {
          path: routes.downloadedCandidate,
          element: lazyLoadRoutes('candidate/downloaded', true)
        },
        {
          path: routes.savedCandidate,
          element: lazyLoadRoutes('candidate/saved', true)
        },
        {
          path: `${routes.applications}/:jobId`,
          element: lazyLoadRoutes('candidate/applications-job', true)
        },
        {
          path: routes.candidateSearch,
          element: lazyLoadRoutes('candidate/search-candidate', true)
        },
        {
          path: routes.searchCandidateResult,
          element: lazyLoadRoutes('candidate/CandidateSearch', true)
        },
        {
          path: `${routes.candidateReview}/:id`,
          element: lazyLoadRoutes('candidate/candidate-review', true)
        },
        {
          path: `${routes.addCandidateReview}/:id`,
          element: lazyLoadRoutes('candidate/add-candidate-review', true)
        },
        /**
         * Jobs routes
         */
        {
          path: `${routes.jobs}:type`,
          element: lazyLoadRoutes('jobs/job-list', true)
        },
        {
          path: routes.postJob,
          element: lazyLoadRoutes('jobs/post-job', true)
        },
        {
          path: `${routes.postJob}/:id`,
          element: lazyLoadRoutes('jobs/post-job', true)
        },
        {
          path: routes.draftJob,
          element: lazyLoadRoutes('jobs/draft-job', true)
        },
        {
          path: `${routes.viewJob}/:slug`,
          element: lazyLoadRoutes('jobs/view-job', true)
        },
        /**
         * Profile routes
         */
        {
          path: routes.updateProfile,
          element: lazyLoadRoutes('profile/recruiter-profile', true)
        },
        {
          path: routes.updateCompanyProfile,
          element: lazyLoadRoutes('profile/company-profile', true)
        },
        {
          path: routes.changePassword,
          element: lazyLoadRoutes('profile/change-password', true)
        },
        {
          path: routes.viewMessages,
          element: lazyLoadRoutes('profile/view-messages', true)
        },
        {
          path: routes.thankYou,
          element: lazyLoadRoutes('thank-you')
        },
        {
          path: routes.notFound,
          element: lazyLoadRoutes('not-found')
        }
      ]
    },
    {
      path: '*',
      element: lazyLoadRoutes('not-found')
    }
  ];

  return useRoutes(appRoutes);
}
