import React from 'react';
import Header from '../shared/header';
import CommanFooter from '../shared/commanFooter';

const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div style={{ minHeight: '70vh' }}>{children}</div>
      <CommanFooter />
    </div>
  );
};

export default MainLayout;
