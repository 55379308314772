export const Auth_Message = 'Access Token Expired or Invalid';

export const whitelistedCountries = ['US', 'CA'];
export const whitelistedIPs = [
  '13.79.16.226',
  '52.169.108.60',
  '180.211.112.179',
  '27.56.183.218',
  '106.213.154.142',
  '182.65.193.56',
  '122.170.174.186'
];

const defaultSelectOpt = { key: '-- Select --', value: '' };
export const RESUME_UPDATE = [
  defaultSelectOpt,
  { key: '1 Day ago', value: '1' },
  { key: '1 Week ago', value: '7' },
  { key: '1 Month ago', value: '30' },
  { key: '6 months ago', value: '180' },
  { key: '1 Year ago', value: '365' }
];

export const TRAVEL_RANGE = [
  { key: 'None', value: '' },
  { key: 'Upto 25 Miles', value: '25' },
  { key: 'Upto 50 Miles', value: '50' },
  { key: 'Upto 75 Miles', value: '75' },
  { key: 'Upto 100 Miles', value: '100' }
];

export const SORTING = [
  { key: 'Profile Updated - Most Recent', value: 'Profile Updated - Most Recent' },
  { key: 'Resume Updated - Most Recent', value: 'Resume Updated - Most Recent' }
];
export const TRAVEL_RANGE_PROFILE = [
  { key: 'None', value: '0' },
  { key: 'Upto 5 Miles', value: '5' },
  { key: 'Upto 10 Miles', value: '10' },
  { key: 'Upto 20 Miles', value: '20' },
  { key: 'Upto 30 Miles', value: '30' },
  { key: 'Upto 40 Miles', value: '40' },
  { key: 'Upto 50 Miles', value: '50' },
  { key: 'Upto 100 Miles', value: '100' }
];

export const WITHIN_CITY_RANGE = [
  { key: 'None', value: '' },
  { key: 'Upto 50 Miles', value: '50' },
  { key: 'Upto 80 Miles', value: '80' },
  { key: 'Upto 100 Miles', value: '100' }
];

export const TOTAL_HIRING_EXPERIENCE = [
  { key: 'Fresher', value: '0' },
  { key: '1 Year', value: '1' },
  { key: '2 Years', value: '2' },
  { key: '3 Years', value: '3' },
  { key: '4 Years', value: '4' },
  { key: '5 Years', value: '5' },
  { key: '6 Years', value: '6' },
  { key: '7 Years', value: '7' },
  { key: '8 Years', value: '8' },
  { key: '9 Years', value: '9' },
  { key: '10 Years', value: '10' },
  { key: '11 Years', value: '11' },
  { key: '12 Years', value: '12' },
  { key: '13 Years', value: '13' },
  { key: '14 Years', value: '14' },
  { key: '15 Years', value: '15' },
  { key: '16 Years', value: '16' },
  { key: '17 Years', value: '17' },
  { key: '18 Years', value: '18' },
  { key: '19 Years', value: '19' },
  { key: '20 Years', value: '20' },
  { key: '21 Years', value: '21' },
  { key: '22 Years', value: '22' },
  { key: '23 Years', value: '23' },
  { key: '24 Years', value: '24' },
  { key: '25 Years', value: '25' },
  { key: '26 Years', value: '26' },
  { key: '27 Years', value: '27' },
  { key: '28 Years', value: '28' },
  { key: '29 Years', value: '29' },
  { key: '30 Years', value: '30' }
];

export const EMPLOYER_HIRE_FOR = [
  { key: 'Executive Level', value: '4' },
  { key: 'Senior Level', value: '5' },
  { key: 'Mid Level', value: '6' },
  { key: 'Entry Level', value: '7' }
];

export const TRAVEL_REQUIREMENTS = [
  defaultSelectOpt,
  { key: 'Not Open to Travel', value: '0' },
  { key: 'Up to 25%', value: '25' },
  { key: 'Up to 50%', value: '50' },
  { key: 'Up to 75%', value: '75' },
  { key: 'Up to 100%', value: '100' }
];

export const INTERVIEW_REMIND = [
  { key: '30 Minutes Before', value: 30 },
  { key: '60 Minutes Before', value: 60 },
  { key: '90 Minutes Before', value: 90 },
  { key: '120 Minutes Before', value: 120 }
];

export const INTERVIEW_MODE = [
  { key: 'Telephonic', value: 'Telephonic' },
  { key: 'F2F', value: 'F2F' },
  { key: 'Skype', value: 'Skype' },
  { key: 'Other', value: 'Other' }
];

export const TIME_ZONE = [
  { key: 'EST', value: 'EST' },
  { key: 'PST', value: 'PST' },
  { key: 'CST', value: 'CST' }
];

export const SKILL_LEVEL = [
  { key: 'Beginner', value: 'Beginner' },
  { key: 'Intermediate', value: 'Intermediate' },
  { key: 'Expert', value: 'Expert' }
];

export const NO_OF_EMPLOYEE = [
  { key: '0-1 employees', value: '0-1' },
  { key: '2-10 employees', value: '2-10' },
  { key: '11-50 employees', value: '11-50' },
  { key: '51-200 employees', value: '51-200' },
  { key: '201-500 employees', value: '201-500' },
  { key: '501-1,000 employees', value: '501-1,000' },
  { key: '1,001-5,000 employees', value: '1,001-5,000' },
  { key: '5,001-10,000 employees', value: '5,001-10,000' },
  { key: '10,001+ employees', value: '10,0001+' }
];

export const PREFERRED_WORK = [
  defaultSelectOpt,
  { key: 'Remote', value: 'Remote' },
  { key: 'On-Site', value: 'On-Site' },
  { key: 'Hybrid', value: 'Hybrid' },
  { key: 'No preference', value: 'No preference' }
];

export const WORK_ARRANGEMENT = [
  { key: 'On-Site', value: 0 },
  { key: 'Hybrid', value: 2 },
  { key: 'Remote', value: 1 },
  { key: 'Flexible', value: 3 }
];

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const EMP_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const EXCLUDED_DOMAINS =
  /(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|icloud\.com|protonmail\.com|mail\.com|yandex\.com|live\.com|inbox\.com|zoho\.com)$/i;

export const SOCIAL_MEDIA = {
  FACEBOOK: 'https://www.facebook.com/OfficialVeriiPro/',
  TWITTER: 'https://www.twitter.com/VeriiPro',
  LINKEDIN: 'https://www.linkedin.com/company/veriiproofficial/',
  PINTREST: 'https://www.pinterest.com/VeriiProOfficial/'
};

export const ZIP_REGEX = {
  US: /^\d{5}$/,
  CA: /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/
};

export const passwordValidation = {
  regex: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,15}$/,
  message:
    'must be 8 to 15 characters long, one uppercase, one lowercase, one number and one special case character like !@#$%^&*',
  maxLength: 15
};

export const annualySalaryRange = {
  MIN_SALARY: 10000,
  MAX_SALARY: 900000
};
export const hourlySalaryRange = {
  MIN_SALARY: 1,
  MAX_SALARY: 1000
};

export const PHONE_NUMBER_REGEX = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;

export const MONTH_SHORT_NAME_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const WILLING_TO_RELOCATION = [
  defaultSelectOpt,
  { key: 'No', value: '0' },
  { key: 'Yes', value: '1' }
];
