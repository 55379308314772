import { lazy, Suspense } from 'react';
import { PrivateRoute } from './route-gaurd';
import MainLayout from '../components/layout/MainLayout';

export function lazyLoadRoutes(componentName, isPrivate, hasHeaderFooter = true) {
  const LazyElement = lazy(() => import(`../views/${componentName}.js`));

  return (
    <Suspense
      fallback={
        <div id="preloader" className="preloader-spinner">
          <div id="status">
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
      }>
      {hasHeaderFooter ? (
        <MainLayout>
          {isPrivate ? (
            <PrivateRoute componentName={componentName}>
              <LazyElement />
            </PrivateRoute>
          ) : (
            <LazyElement />
          )}
        </MainLayout>
      ) : isPrivate ? (
        <PrivateRoute componentName={componentName}>
          <LazyElement />
        </PrivateRoute>
      ) : (
        <LazyElement />
      )}
    </Suspense>
  );
}
