import React, { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomToolTip = ({ text, children, placement = 'top', isFullWidth = true }) => {
  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      trigger={['hover', 'focus']}
      overlay={(props) => (
        <Tooltip {...props} className={isFullWidth ? 'custom-tooltip' : 'custom-tooltip-max-200'}>
          {text}
        </Tooltip>
      )}
      placement={placement}>
      {children}
    </OverlayTrigger>
  );
};

export default memo(CustomToolTip);
